<script setup lang="ts">
const { trackEvent } = useTracking()
const emits = defineEmits<{
  'close-modal': []
}>()
const localePath = useLocalePath()
const props = defineProps<{
  isBonder: boolean
}>()

const showModal = ref(props.isBonder)

const closeModal = () => {
  showModal.value = false

  emits('close-modal')
}

onMounted(() => {
  if (showModal.value) {
    trackEvent({
      event: 'popin',
      category: 'redirection',
      label: 'bonderco.com',
    })
  }
})
</script>

<template>
  <BaseModalV2
    :model-value="showModal"
    data-cy="modal-bonderco"
    size="s"
    @close="closeModal"
  >
    <template #content>
      <div class="text-left">
        <div class="w-[200px]">
          <BaseNuxtImg
            :src="'/production/assets/images/bonderco/logo.svg'"
            width="200"
            height="16"
            class="!mx-0 mt-8"
            alt="Bonderco"
          />
        </div>

        <div class="font-monospace font-bold mt-10 mb-4 text-5xl leading-9">
          {{ $t('static.bonder.popin.title') }}<br />
          {{ $t('static.bonder.popin.brand') }}
        </div>

        <p class="text-base mb-6">
          {{ $t('static.bonder.popin.text1') }}
          <base-button
            color="black"
            :to="localePath('bonder')"
            variant="link"
            @click="closeModal"
          >
            {{ $t('static.bonder.popin.link') }}
          </base-button>
        </p>
        <p class="text-base mb-14">
          {{ $t('static.bonder.popin.text2') }}
        </p>
        <div class="relative h-12">
          <base-button
            class="text-md absolute right-0 top-0"
            color="secondary"
            data-cy="close-modal"
            @click="closeModal"
          >
            {{ $t('static.bonder.popin.close') }}
          </base-button>
        </div>
      </div>
    </template>
  </BaseModalV2>
</template>
